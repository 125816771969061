import { useEffect, useState, useCallback } from 'react'
import { useLocation, Link as RouterLink } from 'react-router-dom'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  chakra,
  Icon,
} from '@chakra-ui/react'
import { BiChevronRight } from 'react-icons/bi'
import { useAppSelector } from '@/hooks'
import { selectBreadCrumbDetails } from '@/store/breadCrumbDetailsSlice'
import { SkeletonSpan } from '@/components/atoms'

type BreadcrumbStage = {
  children?: Record<string, BreadcrumbStage>
  color?: string
  fontWeight?: string
  isDetailsPage?: boolean
  label: string
  route?: string
}

const breadcrumbsLevels: Record<string, BreadcrumbStage> = {
  admin: {
    label: 'Admin',
    color: 'gray.600',
    children: {
      users: {
        label: 'Users',
        route: '/admin/users',
        color: 'iris.500',
        children: {
          'user-details': {
            label: 'User Details',
            route: '/admin/users/:id',
            isDetailsPage: true,
          },
        },
      },
      invites: {
        label: 'Invites',
        route: '/admin/invites',
        color: 'iris.500',
      },
      groups: {
        label: 'Groups',
        route: '/admin/groups',
        color: 'iris.500',
        children: {
          'group-details': {
            label: 'Group Details',
            route: '/admin/groups/:id',
            isDetailsPage: true,
          },
        },
      },
      roles: {
        label: 'Roles',
        route: '/admin/roles',
        color: 'iris.500',
        children: {
          'role-details': {
            label: 'Role Details',
            route: '/admin/roles/:id',
            isDetailsPage: true,
          },
        },
      },
    },
  },
  cluster: {
    label: 'Clusters',
    route: '/cluster',
    color: 'iris.500',
    children: {
      'cluster-details': {
        label: 'Cluster Details',
        route: '/cluster/:id',
        isDetailsPage: true,
      },
    },
  },
  lm: {
    label: 'Licenses',
    color: 'gray.600',
    fontWeight: 'normal',
    children: {
      configurations: {
        label: 'License Configurations',
        route: '/lm/configurations',
        color: 'iris.500',
        children: {
          'configuration-details': {
            label: 'Configuration Details',
            route: '/lm/configurations/:id',
            isDetailsPage: true,
          },
        },
      },
      inventory: {
        label: 'In Use',
        route: '/lm/inventory',
        color: 'iris.500',
      },
    },
  },
  jobs: {
    label: 'Jobs',
    color: 'gray.600',
    fontWeight: 'normal',
    children: {
      'job-script-templates': {
        label: 'Job Script Templates',
        route: '/jobs/job-script-templates',
        color: 'iris.500',
        children: {
          'template-details': {
            label: 'Job Script Template Details',
            route: '/jobs/job-script-templates/:id',
            isDetailsPage: true,
          },
        },
      },
      'job-scripts': {
        label: 'Job Scripts',
        route: '/jobs/job-scripts',
        color: 'iris.500',
        children: {
          'script-details': {
            label: 'Job Script Details',
            route: '/jobs/job-scripts/:id',
            isDetailsPage: true,
          },
        },
      },
      'job-submissions': {
        label: 'Job Submissions',
        route: '/jobs/job-submissions',
        color: 'iris.500',
        children: {
          'submission-details': {
            label: 'Job Submission Details',
            route: '/jobs/job-submissions/:id',
            isDetailsPage: true,
          },
        },
      },
    },
  },
  storage: {
    label: 'Storage',
    route: '/storage',
    color: 'iris.500',
    children: {
      'storage-details': {
        label: 'Storage Details',
        route: '/storage/:id',
        isDetailsPage: true,
      },
    },
  },
  support: {
    label: 'Support',
    route: '/support',
    color: 'iris.500',
    children: {
      'submission-details': {
        label: 'Support Details',
        route: '/support/:id',
        isDetailsPage: true,
      },
    },
  },
  'privacy-policy': {
    label: 'Privacy Policy',
  },
}

// this function is a helper to solve paths with dynamic params
const getChild = (children: Record<string, BreadcrumbStage>, route: string) => {
  const childrenArray = Object.values(children)
  const relatedChild = childrenArray.find((child) =>
    route.startsWith(child?.route ?? '')
  )
  if (relatedChild) return relatedChild
  // replace the last element by a /:id to match the route
  const matchedRoute = route.replace(/\/[^/]+$/, '/:id')
  return childrenArray.find((child) => child.route === matchedRoute)
}

const HeaderBreadcrumbs = ({ isHomePage }: { isHomePage: boolean }) => {
  const breadcrumbDetails = useAppSelector(selectBreadCrumbDetails)
  const location = useLocation()
  const [flattenedCrumbs, setFlattenedCrumbs] = useState<BreadcrumbStage[]>([])

  const getRecursiveCrumbs = useCallback(
    (
      splitRoutes: string[],
      crumbs: any = breadcrumbsLevels,
      index: number = 0
    ) => {
      const breadcrumbObj =
        index > 0
          ? getChild(crumbs, location.pathname)
          : crumbs[splitRoutes[index]]
      if (breadcrumbObj) {
        setFlattenedCrumbs((flattenedCrumbs) =>
          flattenedCrumbs.concat(breadcrumbObj)
        )
        if (breadcrumbObj.children) {
          getRecursiveCrumbs(splitRoutes, breadcrumbObj.children, index + 1)
        }
      }
    },
    [location.pathname, setFlattenedCrumbs]
  )

  useEffect(() => {
    setFlattenedCrumbs([])
    const splitRoutes = location.pathname.split('/').filter(Boolean)
    getRecursiveCrumbs(splitRoutes)
  }, [location.pathname, getRecursiveCrumbs])

  return (
    <Breadcrumb
      paddingLeft={5}
      spacing={1}
      separator={
        <Icon
          as={BiChevronRight}
          color='gray.500'
          boxSize={5}
          verticalAlign='middle'
          marginTop='-3px'
        />
      }
    >
      {/* forces first breadcrumb to assume fix height and prevents jumps */}
      <BreadcrumbItem height='24px'>
        <BreadcrumbLink
          color={isHomePage ? 'white' : 'iris.500'}
          as={RouterLink}
          to='/'
          fontSize='xs'
          fontWeight='semibold'
        >
          Home
        </BreadcrumbLink>
      </BreadcrumbItem>

      {flattenedCrumbs.map((breadcrumb, index, original) => {
        if (breadcrumb.label === 'Admin') return null

        const isCurrentPage = index + 1 === original.length

        if (breadcrumb.isDetailsPage && !!breadcrumbDetails) {
          if (breadcrumbDetails.isLoading) {
            return (
              <BreadcrumbItem
                key={breadcrumb.label}
                alignItems='flex-end'
                isCurrentPage={isCurrentPage}
              >
                {/* BreadcrumbItem only accepts links and spans as children, so a span skeleton is needed */}
                <SkeletonSpan>{breadcrumb.label}</SkeletonSpan>
              </BreadcrumbItem>
            )
          }
          if (breadcrumbDetails.name) {
            return (
              <BreadcrumbItem
                key={breadcrumb.label}
                isCurrentPage={isCurrentPage}
                height='24px'
              >
                <chakra.span color='gray.600' fontSize='xs' lineHeight='16px'>
                  {breadcrumbDetails.name}
                </chakra.span>
              </BreadcrumbItem>
            )
          }
          // fallback if service fails
          if (breadcrumbDetails.isError) {
            return (
              <BreadcrumbItem
                key={breadcrumb.label}
                isCurrentPage={isCurrentPage}
                height='24px'
              >
                <chakra.span color='gray.600' fontSize='xs' lineHeight='16px'>
                  404
                </chakra.span>
              </BreadcrumbItem>
            )
          }
        }

        if (isCurrentPage && !breadcrumb.isDetailsPage) {
          return (
            <BreadcrumbItem
              key={breadcrumb.label}
              isCurrentPage={isCurrentPage}
            >
              <chakra.span color='gray.600' fontSize='xs' lineHeight='16px'>
                {breadcrumb.label}
              </chakra.span>
            </BreadcrumbItem>
          )
        }

        if (breadcrumb.route && !breadcrumb.isDetailsPage) {
          return (
            <BreadcrumbItem key={breadcrumb.label}>
              <BreadcrumbLink
                color={breadcrumb.color}
                as={RouterLink}
                to={breadcrumb.route}
                fontSize='xs'
                fontWeight={breadcrumb.fontWeight || 'semibold'}
              >
                {breadcrumb.label}
              </BreadcrumbLink>
            </BreadcrumbItem>
          )
        }

        if (!breadcrumb.route && !breadcrumb.isDetailsPage) {
          return (
            <BreadcrumbItem key={breadcrumb.label}>
              <chakra.span
                color={breadcrumb.color}
                fontSize='xs'
                fontWeight={breadcrumb.fontWeight || 'semibold'}
                lineHeight='16px'
              >
                {breadcrumb.label}
              </chakra.span>
            </BreadcrumbItem>
          )
        }

        // fallback
        return <BreadcrumbItem key={breadcrumb.label} />
      })}
    </Breadcrumb>
  )
}

export default HeaderBreadcrumbs
